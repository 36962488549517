import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/vercel/path0/apps/dashboard/app/react-query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/apps/dashboard/app/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CommandMenu"] */ "/vercel/path0/apps/dashboard/components/dashboard/command-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/apps/dashboard/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/apps/dashboard/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageview","PHProvider"] */ "/vercel/path0/apps/dashboard/providers/PostHogProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/dashboard/styles/tailwind/tailwind.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@4.29.10_next@14.2.5_react-dom@18.3.1_react@18.3.1/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","MultisessionAppSupport","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@4.29.10_next@14.2.5_react-dom@18.3.1_react@18.3.1/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["EmailLinkErrorCode","MagicLinkErrorCode","WithClerk","WithSession","WithUser","isClerkAPIResponseError","isEmailLinkError","isKnownError","isMagicLinkError","isMetamaskError","useAuth","useClerk","useEmailLink","useMagicLink","useOrganization","useOrganizationList","useOrganizations","useSession","useSessionList","useSignIn","useSignUp","useUser","withClerk","withSession","withUser"] */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@4.29.10_next@14.2.5_react-dom@18.3.1_react@18.3.1/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@4.29.10_next@14.2.5_react-dom@18.3.1_react@18.3.1/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.4.1_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_@opentelemetry+api@1.4.1_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/fonts/PangeaAfrikanTrial-Medium.woff2\",\"variable\":\"--font-pangea\"}],\"variableName\":\"pangea\"}");
